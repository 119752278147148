
@import '/node_modules/bootstrap/scss/bootstrap';

$black: #000;
$grey: #b9b9b9;
$light-grey: #ebebeb;
$white: #fff;
$red: #e74c3c;
$orange: #e67e22;
$green: #27ae60;
$blue: #2980b9;
$grey_aso: #222222;
$red_aso: #e51e10;
$grey_create_group: #6c6c6c;
$riyadh: #EB5C28;

$color-marathon: #148434;

$color-primary: #444444;
$color-secondary: #2D2D2D;
$color-third: $orange;
$color-success: $green;
$color-danger: $red;
$body-bg: #f5f5f5;
$bg-button: #ebebeb;

.Recover {
    text-align: center;
    //display: flex;
    height: 100%;

    .title {
      padding: 0.5em;
      display: block;
      font-weight: bold;
      transform: skewX(-10deg);
      background: #EB5C28;
      color: #fff;
      margin: 0.5em;
      display: inline-block;
      text-transform: uppercase;
      font-size: 1.5em;
    }
  
    form {
      margin-top: 40px;
    }

    .subtitle{
      font-size: 1em;
      color: grey;
      margin: 1em 0.5em 0.5em 0.5em;
      box-sizing: border-box;
      display: block;
      margin-block-start: 1em;
      margin-block-end: 1em;
      margin-inline-start: 0px;
      margin-inline-end: 0px;
      padding: 0.5em;
    }

    .inputemail {
      width: 80%;
      // text-align: center;
    }
  
    .recover-header {
      margin-bottom: 70px;
      text-transform: uppercase;
  
      h3 {
        color: $color-secondary;
        font-size: 20px;
      }
  
      img {
        width: 37px;
      }
    }
  
    .submit-button {
      text-align: center;
      button {
        font-weight: bold;
        font-size: 1.3em;
        padding: 1rem 4rem;
        color: white;
        border-color: #000;
        border-width: 0.1em;
        border-radius: 1em;
      }
    }
  
    .form-group {
      margin-bottom: 40px;
  
  
      input {
        min-width: 100px;
      }
  
      i {
        position: absolute;
        top: 10px;
        right: 10px;
        color: $black;
      }
  
      &:last-child {
        margin-top: 60px;
      }
  
      button[type="submit"] {
        background-color: $riyadh;
        &:hover {
          background-color: white;
          color: $riyadh;
        }
      }
    }
}
  